export default function Component() {
  return (
    <div className="col-12 mb-4">
      <div className="card shadow-sm">
        <div className="card-body">
          <div className="card-title placeholder-glow">
            <h5 className="d-inline align-top">
              <span className="placeholder col-4" />
            </h5>
            &nbsp;
            <span className="text-muted align-top">
              <span className="placeholder col-2" />
            </span>
          </div>
          <div className="card-text placeholder-glow">
            <div className="px-3 py-2">
              <span className="placeholder col-12" />
              <span className="placeholder col-3" />
              <span className="placeholder col-4" />
              <span className="placeholder col-8" />
            </div>
          </div>
          <div className="card-footer">
            <a
              aria-disabled="true"
              className="btn btn-primary disabled placeholder col-2 me-2"
              href="#"
            />
            <a
              aria-disabled="true"
              className="btn btn-primary disabled placeholder col-2 me-2"
              href="#"
            />
            <a
              aria-disabled="true"
              className="btn btn-primary disabled placeholder col-2"
              href="#"
            />
          </div>
        </div>
      </div>
    </div>
  );
}