"use client";

// @ts-ignore
import CanvasJSReact from "@canvasjs/react-charts"
import { IChart } from "@/models/chart"

export default function Component(
  { chart }: { chart: IChart }
) {
  return (
    <div className="text-center">
      <CanvasJSReact.CanvasJSChart
        options={{
          animationEnabled: true,
          exportEnabled: true,
          title: {
            text: chart.name,
          },
          axisX: {
            valueFormatString: "DD MMM",
          },
          axisY: {
            title: "Price",
            prefix: chart.y_symbol ? chart.y_symbol : "",
          },
          toolTip: {
            content: `Date: {x}<br/>Price: ${chart.y_symbol ? chart.y_symbol : ""}{y}`,
          },
          data: chart.datasets.map((dataset) => {
            return {
              type: "candlestick",
              fillOpacity: 0.5,
              showInLegend: true,
              name: dataset.name,
              yValueFormatString: `${chart.y_symbol ? chart.y_symbol : ""}###0.00`,
              xValueFormatString: "DD/MM/YY HH:mm",
              dataPoints: dataset.data.map((data) => {
                if (dataset.dataset_type == "kline") {
                  return {
                    x: new Date(data.x),
                    y: [
                      data.y[1],
                      data.y[2],
                      data.y[3],
                      data.y[4],
                    ],
                  }
                }
                else {
                  return {
                    x: new Date(data.x),
                    y: [
                      data.y[0],
                      data.y[1],
                      data.y[2],
                      data.y[3],
                    ],
                  }
                }
              }),
            }
          }),
        }}
      />
    </div>
  )
}
