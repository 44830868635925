import { IPage } from "@/models/page"
import Link from "next/link";

export default function Component(
  { page }: { page: IPage }
) {
  if (page.video != null) {
    return (
      <div className="card">
        <div className="card-body embed-responsive">
          <h5 className="card-title">{page.title}</h5>
          <p className="card-text">{page.description}</p>
          <iframe
            className="video"
            width="560"
            height="315"
            src={page.video}
            title={page.title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    )
  }
  else {
    return (
      <div
        className="card"
      >
        { page.image && (
          <a
            href={page.url}
            target="_blank"
          >
            <img
              src={page.image}
              alt={page.title}
              className="card-img-top"
            /> 
          </a>
        )}
        <div className="card-body">
          <h5 className="card-title">
            {
              page.image == null ? (
                <Link
                  href={page.url}
                  target="_blank"
                >
                  {page.title == null ? page.url : page.title}
                </Link>
              ) : (
                <>
                  {page.title == null ? page.url : page.title}
                </>
              )
            }
          </h5>
          <p className="card-text">{page.description}</p>
        </div>
      </div>
    )
  }
}
