"use client";

//import ReactSpeedometer from "react-d3-speedometer"
import { IGauge } from "@/models/gauge"

export default function Component(
  { gauge }: { gauge: IGauge }
) {
  return (
    <div className="text-center">
    </div>
  )
}
