"use client";

import { apiAuthSession } from "@/lib/api-method";
import { Button, Dropdown } from "react-bootstrap";
import { IPost } from "@/models/post";
import { Session } from "next-auth";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import Image from "next/image";
import Link from "next/link";

export default function Component(
  { post, handleFollow }: { post: IPost, handleFollow?: () => void }
) {
  const [ session, setSession ] = useState<Session | null>(null);

  useEffect(() => {
    apiAuthSession()
      .then(([status, session]) => {
        if (status == 200)
          setSession(session);
      });
  }, []);

  return (
    <div className="row">
      {
        post.user.image_file !== undefined || post.user.image_file !== null ?
        <div className="col-md-2 col-lg-1">
          <Link href={`/@${post.user.username}`}>
            <Image
              alt={post.user.name}
              src={post.user.image_file.path}
              className="rounded-circle"
              width="64"
              height="64"
            />
          </Link>
          &nbsp;
        </div>
        : ""
      }
      <div className="col">
        <h5 className="d-inline align-middle">
          <Link href={`/@${post.user.username}`}>
            {post.user.name}
          </Link>
        </h5>
        <br />
        <span className="text-muted">
          @{post.user.username}
        </span>
        ,
        &nbsp;
        <time
          className="text-muted"
          dateTime={dayjs(post.created_at).toISOString()}
        >
          {dayjs(post.created_at).fromNow()}
        </time>
      </div>
      <div className="col-sm-2 col-md-1 text-right">
        <Dropdown className="">
          <Dropdown.Toggle
            variant="link"
            id="dropdownMenuButton"
          >
            <i className="bi bi-three-dots"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            { session?.user != null && post.user.username == session?.user.username ? (
              <>
                <Dropdown.Item
                  href={ `/posts/${post._id}/edit` }
                >
                  Edit
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                >
                  Delete
                </Dropdown.Item>
              </>
            ) : "" }
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}