import { IContent } from "@/models/content"
import CandleStickChartComponent from "@/components/content/charts/candlestick";
import GaugeComponent from "@/components/content/gauge";
import Image from "next/image";
import linkifyHtml from "linkify-html";
import Markdown from 'react-markdown';
import PageComponent from "@/components/content/page";

export default function Component({ content, index }: { content: IContent, index: number}) {
  var text = content.text ?? "";

  if (index == 0) {
    text = text.replace(/^(.*)$/m, "# $1");
  }

  return (
    <div className="my-4">
      {content.attachment && (
        <Image
          alt={content.attachment.file_name}
          className="img-fluid"
          src={content.attachment.path}
          width={0}
          height={0}
          sizes="100vw"
          style={{ width: "100%", height: "auto" }} 
        />
      )}
      {content.gauge && (
        <GaugeComponent gauge={content.gauge} />
      )}
      {content.chart && (
        <CandleStickChartComponent chart={content.chart} />
      )}
      <Markdown>{text}</Markdown>
      {content.page && (
        <PageComponent page={content.page} />
      )}
    </div>
  )
}