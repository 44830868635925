"use client";

import { apiAuthSession } from "@/lib/api-method";
import { Button, Dropdown } from "react-bootstrap";
import { IPost } from "@/models/post";
import { Session } from "next-auth";
import { useEffect, useState } from "react";
import Link from "next/link";
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookShareCount,
  LineShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

export default function Component(
  { post, handleLike }: {
    post: IPost,
    handleLike?: () => void
  }
) {
  const [ session, setSession ] = useState<Session | null>(null);
  const url = `${process.env.NEXT_PUBLIC_WEB_URL}/posts/${post._id}`;

  useEffect(() => {
    apiAuthSession()
      .then(([status, session]) => {
        if (status == 200)
          setSession(session);
      });
  }, []);

  return (
    <div className="row">
      <div className="col-auto">
        {
          post.like ? (
            <Button
              onClick={handleLike}
              variant="outline-primary"
            >
              <i className="bi bi-hand-thumbs-up-fill"></i>
              &nbsp;
              {post.like_count > 0 ? post.like_count : ""}
            </Button>
          ) : (
            <Button
              onClick={handleLike}
              variant="primary"
            >
              <i className="bi bi-hand-thumbs-up"></i>
              &nbsp;
              {(post.like_count ?? 0) > 0 ? post.like_count : ""}
            </Button>
          )
        }
      </div>
      <div className="col-auto">
        <Link
          className="btn btn-primary"
          href={ `/posts/${post._id}` }
        >
          <i className="bi bi-file-richtext"></i>
          &nbsp;
          Read More
        </Link>
      </div>
      <div className="col-auto text-end">
        <Dropdown>
          <Dropdown.Toggle variant="primary" id="dropdown-share">
            <i className="bi bi-share"></i>
            &nbsp;
            Share
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>
              <FacebookShareButton url={url}>
                <i className="bi bi-facebook"></i>
                &nbsp;
                Facebook
                <FacebookShareCount url={url} />
              </FacebookShareButton>
            </Dropdown.Item>
            <Dropdown.Item>
              <TwitterShareButton url={url}>
                <i className="bi bi-twitter"></i>
                &nbsp;
                Twitter
              </TwitterShareButton>
            </Dropdown.Item>
            <Dropdown.Item>
              <WhatsappShareButton url={url}>
                <i className="bi bi-whatsapp"></i>
                &nbsp;
                WhatsApp
              </WhatsappShareButton>
            </Dropdown.Item>
            <Dropdown.Item>
              <TelegramShareButton url={url}>
                <i className="bi bi-telegram"></i>
                &nbsp;
                Telegram
              </TelegramShareButton>
            </Dropdown.Item>
            <Dropdown.Item>
              <LinkedinShareButton url={url}>
                <i className="bi bi-linkedin"></i>
                &nbsp;
                LinkedIn
              </LinkedinShareButton>
            </Dropdown.Item>
            <Dropdown.Item>
              <RedditShareButton url={url}>
                <i className="bi bi-reddit"></i>
                &nbsp;
                Reddit
              </RedditShareButton>
            </Dropdown.Item>
            <Dropdown.Item>
              <EmailShareButton url={url}>
                <i className="bi bi-envelope"></i>
                &nbsp;
                Email
              </EmailShareButton>
            </Dropdown.Item>
            <Dropdown.Item>
              <LineShareButton url={url}>
                <i className="bi bi-line"></i>
                &nbsp;
                Line
              </LineShareButton>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}